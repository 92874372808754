export const tanyaEventNames = {
  questionSearch: "question search initiated",
  searchResult: "search result shown",
  liveClassBannerClicked: "live class banner clicked"
};

export const videoPlayerEventNames = {
  videoShared: "video shared"
};

export const bannerIntentEventNames = {
  primaryCTAClicked: "primary CTA clicked on web banner",
  leadModalViewed: "Bimbel Lead Intent Capture Form Viewed",
  leadPhoneNumberEntered: "Mobile Entered On Lead Intent Capture Form",
  leadNameEntered: "Name Entered On Lead Intent Capture Form",
  leadGradeEntered: "Grade Entered On Lead Intent Capture Form",
  leadSubmitButtonClicked: "Submit Clicked On Lead Intent Capture Form",
  leadSkipButtonClicked: "Skip Clicked On Lead Intent Capture Form",
  leadSuccessScreenViewed: "Success Screen Viewed On Lead Capture Flow",
  leadOkayButtonClickedOnSuccess:
    "Okay Clicked On Success Screen Lead Capture Flow",
  leadReasonPopupViewed: "enter reason why bimbel needed popup viewed",
  leadReasonSubmitClicked: "submit clicked on why bimbel needed popup",
  leadReasonSkipButtonClicked: "skip clicked on why bimbel needed popup"
};

export const featureSelectionEventNames = {
  featureSelectionPopupViewed:
    "feature selection popup viewed in web banner flow",
  featureSelectedOnPopup: "feature selected on feature selection popup",
  featureSelectionSubmitClicked: "submit clicked on feature selection popup",
  featureSelectionPopupClosed: "cross clicked on feature selection popup",
  featureDetailsScreenViewed: "bimbel feature details screen viewed",
  featureDetailsSubmitClicked:
    "submit clicked on bimbel feature details screen",
  featureDetailsPopupClosed: "cross clicked on bimbel feature details screen"
};
